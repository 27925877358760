import axios from 'axios';
import IdleVue from 'idle-vue';
import Vue from 'vue';
import Loading from 'vue-loading-overlay';
import VueTheMask from 'vue-the-mask';
import App from './App.vue';
import config from './config';
import DashboardPlugin from './plugins/dashboard-plugin';
import router from './routes/router';
import store from './store';

Vue.use(IdleVue, {
  eventEmitter: new Vue(),
  store,
  idleTime: 3600000,
  startAtIdle: false,
  events: ['keydown', 'mousedown', 'touchstart']
});

// plugin setup
Vue.use(DashboardPlugin);
Vue.use(Loading, { opacity: 0.2 });

// Plugins from Onboarding Project
Vue.use(VueTheMask);
// end

// Axios setup
axios.defaults.baseURL = config.ROOT_API;
axios.defaults.headers.common['Content-Type'] = 'application/json';

let vueApp = new Vue({ store });
const loader = vueApp.$loading.show();
const app = window.location.pathname.substring(
  1,
  window.location.pathname.indexOf('/', 1)
);
vueApp.$store
  .dispatch('GET_FACILITATOR')
  .then(() => vueApp.$store.dispatch('SSO', app).finally(initializeVue))
  .finally(loader.hide);

function initializeVue(user) {
  const token = localStorage.getItem('userToken');
  if (token) axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  Vue.prototype.$config = config;
  /* eslint-disable no-new */
  Vue.config.ignoredElements = [
    'flex-microform-wc'
  ];
  vueApp = new Vue({
    el: '#app',
    render: h => h(App),
    router,
    store
  });
  if (user && window.location.pathname === '/') {
    vueApp.$router.push({ name: 'Dashboard' });
  }
}
