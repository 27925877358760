<template>
  <div>
    <div class="wrapper wrapper-full-page">
      <notifications></notifications>
      <div :class="pageClass" class="full-page login-background">
        <!-- <div class="background-image"></div> -->
        <div class="centered">
          <zoom-center-transition :duration="pageTransitionDuration" mode="out-in">
            <router-view></router-view>
          </zoom-center-transition>
        </div>
      </div>
    </div>
    <div class="app-version">
      <span>&copy; {{ year }}, Jupico v{{ APP_VERSION }}</span>
    </div>
  </div>
</template>
<script>
import { BaseNav } from 'src/components';
import { ZoomCenterTransition } from 'vue2-transitions';
import { mapGetters } from 'vuex';

export default {
  components: {
    BaseNav,
    ZoomCenterTransition
  },
  props: {
    backgroundColor: {
      type: String,
      default: 'black'
    }
  },
  data() {
    return {
      showMenu: false,
      menuTransitionDuration: 250,
      pageTransitionDuration: 200,
      year: new Date().getFullYear(),
      pageClass: 'applogin-page',
      APP_VERSION: process.env.VUE_APP_VERSION
    };
  },
  computed: {
    title() {
      return `${this.$route.name} Page`;
    },
    ...mapGetters(['getFacilitator'])
  },
  methods: {
    toggleNavbar() {
      document.body.classList.toggle('nav-open');
      this.showMenu = !this.showMenu;
    },
    closeMenu() {
      document.body.classList.remove('nav-open');
      this.showMenu = false;
    },
    setPageClass() {
      this.pageClass = `${this.$route.name}-page`.toLowerCase();
    }
  },
  beforeDestroy() {
    this.closeMenu();
  },
  beforeRouteUpdate(to, from, next) {
    // Close the mobile menu first then transition to next page
    if (this.showMenu) {
      this.closeMenu();
      setTimeout(() => {
        next();
      }, this.menuTransitionDuration);
    } else {
      next();
    }
  },
  mounted() {
    this.setPageClass();
  },
  watch: {
    $route() {
      this.setPageClass();
    }
  }
};
</script>

<style lang="scss">
.login-background {
  min-height: 100vh;
  background: var(--facilitator-theme-bg-color) !important;
}
.background-image {
  background: var(--facilitator-background-img-url) no-repeat;
  position: absolute;
  background-size: 50%;
  background-position: 38%;
  opacity: 0.4;
  width: 100%;
  height: 100%;
}
.navbar.auth-navbar {
  top: 0;
}
$scaleSize: 0.8;
@keyframes zoomIn8 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  100% {
    opacity: 1;
  }
}
.wrapper-full-page .zoomIn {
  animation-name: zoomIn8;
}
@keyframes zoomOut8 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}
.wrapper-full-page .zoomOut {
  animation-name: zoomOut8;
}
.centered {
  width: 100%;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.app-version {
  position: fixed;
  bottom: 0;
  padding: 10px;
  color: var(--facilitator-text-color);
  font-size: 12px;
  z-index: 1000;
  width: 100%;
  text-align: center;
}
</style>
