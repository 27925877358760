// Mutation types
const SET_SIDEBAR_OPEN_MENU = 'ui/SET_SIDEBAR_OPEN_MENU';

// initial state
const state = { sidebarOpenMenu: '' };

// actions
const actions = {
  UPDATE_SIDEBAR_OPEN_MENU({ commit }, openMenuName) {
    commit(SET_SIDEBAR_OPEN_MENU, openMenuName);
  }
};

// getters
const getters = {
  getSidebarOpenMenu: state => state.sidebarOpenMenu
};

// mutations
const mutations = {
  [SET_SIDEBAR_OPEN_MENU](state, data) {
    state.sidebarOpenMenu = data;
  }
};

export default {
  state: { ...state },
  actions,
  getters,
  mutations
};
