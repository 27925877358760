import { datetimeToString } from '@/helpers/date';
import axios from 'axios';
import { ClientJS } from 'clientjs';
import cookies from 'js-cookie';
import { improvedStructuredClone } from '@/helpers/utils';
import config from '../config';
import { JUPITER_APPS, USER_ROLES } from '../data/constants';
import router from '../routes/router';

// Mutation types
const SIGN_IN = 'security/SIGN_IN';
const SIGN_OUT = 'security/SIGN_OUT';
const SET_PROFILE = 'user/SET_PROFILE';
const SET_USER = 'user/SET_USER';
const USER_TOKEN_NAME = 'userToken';
const USER_INFO_NAME = 'userInfo';
const TWO_FACTOR_USER_INFO_NAME = 'twoFactorUserInfo';
const TWO_FACTOR_TOKEN_NAME = 'twoFactorToken';
// Errors
const CHANGE_PASSWORD_ERROR_MESSAGE = 'Sorry, change password failed.';
const CURRENT_PASSWORD_NOT_VALID_ERROR_MESSAGE = 'The current password is not valid.';
const USERNAME_ALREADY_EXISTS_ERROR_MESSAGE = 'The username already exists.';
const EMAIL_ALREADY_EXISTS_ERROR_MESSAGE = 'E-mail already used.';
const UPDATE_USER_ERROR = 'Sorry, edit profile failed';

const ENDPOINTS = {
  SIGN_IN: 'auth/login',
  SEND_2FA_METHOD: 'auth/2fa/send-code',
  VALIDATE_2FA_METHOD: 'auth/2fa/validate-code',
  SIGN_OUT: 'auth/logout',
  GET_USER: 'users/{id}',
  CHANGE_PASSWORD: 'users/{id}/change-password',
  LOGIN_AS: 'auth/login-as',
  SSO: 'auth/sso',
  FORGOT_PASSWORD_EMAIL: 'auth/forgot-password/email',
  FORGOT_PASSWORD_CHANGE: 'auth/forgot-password/change',
  FORGOT_PASSWORD_CHECK: 'auth/forgot-password/check'
};

function initialState() {
  return {
    token: localStorage.getItem(USER_TOKEN_NAME) || null,
    twoFactorToken: localStorage.getItem(TWO_FACTOR_TOKEN_NAME) || null,
    user: JSON.parse(localStorage.getItem(USER_INFO_NAME)) || JSON.parse(localStorage.getItem(TWO_FACTOR_USER_INFO_NAME)) || {},
    profile: {},
    isLoggedInAs: !!(
      localStorage.getItem('adminUserInfo') && localStorage.getItem('adminUserToken')
    )
  };
}

// initial state
const state = initialState();

function getAllowedApplications(applications) {
  const applicationNames = Object.keys(applications);
  return applicationNames.filter(appName => applications[appName].status === 'active');
}
function processLoginResponse(response, application = JUPITER_APPS.PAYMENTS) {
  if (application === JUPITER_APPS.ADMIN) application = JUPITER_APPS.PAYMENTS;
  let token = response.data.token;
  const allowedApplications = getAllowedApplications(response.data.applications);
  let user = {
    id: response.data.userId,
    username: response.data.username,
    allowedApplications,
    accounts: response.data.applications[application].accounts,
    name: response.data.name,
    acl: response.data.applications[application].acl,
    theme: { ...response.data.applications[application].theme },
    notifications: response.data.applications[application].notifications,
    forcePasswordReset: response.data.forcePasswordReset,
    productIds: response.data.applications[application].productIds
  };
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  localStorage.setItem(USER_TOKEN_NAME, token);
  localStorage.setItem(USER_INFO_NAME, JSON.stringify(user));
  localStorage.removeItem(TWO_FACTOR_USER_INFO_NAME);
  localStorage.removeItem(TWO_FACTOR_TOKEN_NAME);
  return { user, token };
}

async function getBrowseInfo() {
  const client = new ClientJS();
  let ipInfo;
  try {
    const response = await axios({
      url: 'https://api.ipfind.com/me?auth=24fa667e-a9ec-4054-96c4-6725b4f9c3ce',
      method: 'GET'
    });
    ipInfo = response.data;
  } catch (error) {
    ipInfo = {};
  }
  const browserInfo = {
    userAgent: client.getUserAgent(),
    datetime: datetimeToString(new Date()),
    ip: ipInfo
  };
  const fingerprint = client.getCustomFingerprint(browserInfo.userAgent, browserInfo.ip.ip_address);
  browserInfo.fingerprint = fingerprint;
  return browserInfo;
}

// actions
const actions = {
  async SIGN_IN({ commit, rootGetters }, credentials) {
    try {
      const browserInfo = await getBrowseInfo();
      const response = await axios.post(ENDPOINTS.SIGN_IN, {
        ...credentials,
        browserInfo,
        facId: rootGetters.getFacilitator.facId
      });
      if (response.data.twoFactorAuthToken) {
        const { mobileNumber, email, userId, twoFactorAuthToken } = response.data;
        axios.defaults.headers.common['X-Two-Factor-Token'] = twoFactorAuthToken;
        localStorage.setItem(TWO_FACTOR_TOKEN_NAME, twoFactorAuthToken);
        localStorage.removeItem(USER_TOKEN_NAME)
        commit(SIGN_IN, { user: { mobileNumber, email, userId }, token: null, twoFactorToken: twoFactorAuthToken })
        localStorage.setItem(TWO_FACTOR_USER_INFO_NAME, JSON.stringify({ mobileNumber, email, userId }));
        return response.data
      }
      const processedLoginResponse = processLoginResponse(response, credentials.application);
      commit(SIGN_IN, processedLoginResponse);
      const hasAccessToAdminApp = response.data.applications[credentials.application].acl.some(
        a => a.role === USER_ROLES.UNDERWRITER || a.role === USER_ROLES.ADMIN || a.role === USER_ROLES.BANK
      );
      if (hasAccessToAdminApp) {
        cookies.set('aSessionId', response.data.sessionId, {
          domain: config.BASE_DOMAIN
        });
      } else {
        cookies.set('sessionId', response.data.sessionId, {
          domain: config.BASE_DOMAIN
        });
      }
      //  if 2FA enabled
      return response.data;
    } catch (error) {
      throw error.response.data.message;
    }
  },
  async SEND_2FA_METHOD({ commit }, data) {
    try {
      const twoFactorAuthToken = localStorage.getItem(TWO_FACTOR_TOKEN_NAME);
      axios.defaults.headers.common['X-Two-Factor-Token'] = twoFactorAuthToken;
      const response = await axios.post(ENDPOINTS.SEND_2FA_METHOD, data);
      return response.data;
    } catch (error) {
      throw error.response.data.message;
    }
  },
  async VERIFY_2FA_CODE({ commit, rootGetters }, data) {
    try {
      const twoFactorAuthToken = localStorage.getItem(TWO_FACTOR_TOKEN_NAME);
      axios.defaults.headers.common['X-Two-Factor-Token'] = twoFactorAuthToken;
      const params = structuredClone(data)
      params.facId = rootGetters.getFacilitator.facId
      params.browserInfo = await getBrowseInfo()
      const response = await axios.post(ENDPOINTS.VALIDATE_2FA_METHOD, params);
      const processedLoginResponse = processLoginResponse(response, data.application);
      commit(SIGN_IN, processedLoginResponse);
      const hasAccessToAdminApp = response.data.applications[data.application].acl.some(
        a => a.role === USER_ROLES.UNDERWRITER || a.role === USER_ROLES.ADMIN || a.role === USER_ROLES.BANK
      );
      if (hasAccessToAdminApp) {
        cookies.set('aSessionId', response.data.sessionId, {
          domain: config.BASE_DOMAIN
        });
      } else {
        cookies.set('sessionId', response.data.sessionId, {
          domain: config.BASE_DOMAIN
        });
      }
      return response.data;
    } catch (error) {
      throw error.response?.data?.message || error.message;
    }
  },
  SIGN_OUT({ state, dispatch }) {
    return new Promise(resolve => {
      axios.post(ENDPOINTS.SIGN_OUT, { token: state.token }).finally(() => {
        dispatch('CLEAR_AUTH_DATA');
        resolve(true);
      });
    });
  },
  CLEAR_AUTH_DATA({ commit }) {
    localStorage.removeItem(USER_TOKEN_NAME);
    localStorage.removeItem(USER_INFO_NAME);
    localStorage.removeItem('adminUserInfo');
    localStorage.removeItem('adminUserToken');
    localStorage.removeItem('previousAdminPage');
    cookies.remove('sessionId', { domain: config.BASE_DOMAIN });
    cookies.remove('aSessionId', { domain: config.BASE_DOMAIN });
    cookies.remove('loggedInAsSessionId', { domain: config.BASE_DOMAIN });
    commit(SIGN_OUT);
  },
  GET_LOGGED_USER({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(ENDPOINTS.GET_USER.replace('{id}', id))
        .then(response => {
          commit(SET_PROFILE, response.data);
          resolve();
        })
        .catch(() => reject(CHANGE_PASSWORD_ERROR_MESSAGE)
        );
    });
  },
  CLEAR_PROFILE({ commit }) {
    commit(SET_PROFILE, {});
  },
  UPDATE_USER({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(ENDPOINTS.GET_USER.replace('{id}', data._id), data)
        .then(() => {
          commit(SET_PROFILE, data);
          resolve();
        })
        .catch(error => {
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            reject(error.response.data.message);
          }
          if (error.response) {
            const { data = {} } = error.response;
            return data.stack.includes('email')
              ? reject(EMAIL_ALREADY_EXISTS_ERROR_MESSAGE)
              : data.stack.includes('username')
                ? reject(USERNAME_ALREADY_EXISTS_ERROR_MESSAGE)
                : reject(UPDATE_USER_ERROR);
          }
        });
    });
  },
  CHANGE_PASSWORD(context, data) {
    return new Promise((resolve, reject) => {
      let user = Object.assign({}, data);
      delete user.id;
      axios
        .post(ENDPOINTS.CHANGE_PASSWORD.replace('{id}', data.id), user)
        .then(() => {
          resolve();
        })
        .catch(error => {
          if (error.response && error.response.status === 401)
            reject(CURRENT_PASSWORD_NOT_VALID_ERROR_MESSAGE);
          reject(CHANGE_PASSWORD_ERROR_MESSAGE);
        });
    });
  },
  ACTION_SET_THEME_SETTINGS({ commit }, data) {
    let userInfo = JSON.parse(localStorage.getItem(USER_INFO_NAME));
    localStorage.setItem(
      'userInfo',
      JSON.stringify({ ...userInfo, theme: { ...userInfo.theme, ...data } })
    );
    commit('SET_THEME_SETTINGS', data);
  },
  LOGIN_AS({ commit }, userId) {
    return new Promise((resolve, reject) => {
      axios
        .post(ENDPOINTS.LOGIN_AS, { userId })
        .then(response => {
          let adminUserInfo = JSON.parse(localStorage.getItem(USER_INFO_NAME));
          let adminUserToken = localStorage.getItem(USER_TOKEN_NAME);
          localStorage.setItem('adminUserInfo', JSON.stringify(adminUserInfo));
          localStorage.setItem('adminUserToken', adminUserToken);
          const processedLoginResponse = processLoginResponse(response);
          commit(SIGN_IN, processedLoginResponse);
          commit('SET_IS_LOGGED_IN_AS', true);
          resolve(processedLoginResponse.user);
        })
        .catch(() => reject(`Error while logging in`));
    });
  },
  async SSO({ commit, dispatch, rootGetters }, application) {
    try {
      const sessionId = cookies.get('sessionId') || cookies.get('aSessionId');
      if (sessionId) {
        const browserFingerprint = (await getBrowseInfo()).fingerprint;
        const response = await axios.post(ENDPOINTS.SSO, {
          sessionId,
          browserFingerprint,
          facId: rootGetters.getFacilitator.facId,
          application
        });
        const processedLoginResponse = processLoginResponse(response);
        commit(SIGN_IN, processedLoginResponse);
        return processedLoginResponse.user;
      } else {
        dispatch('CLEAR_AUTH_DATA');
        return false;
      }
    } catch (error) {
      dispatch('CLEAR_AUTH_DATA');
      return false;
    }
  },
  EXIT_FROM_LOGGED_IN_AS({ commit }) {
    let adminUserInfo = JSON.parse(localStorage.getItem('adminUserInfo'));
    let adminUserToken = localStorage.getItem('adminUserToken');
    localStorage.removeItem('adminUserInfo');
    localStorage.removeItem('adminUserToken');
    localStorage.setItem(USER_INFO_NAME, JSON.stringify(adminUserInfo));
    localStorage.setItem(USER_TOKEN_NAME, adminUserToken);
    axios.defaults.headers.common['Authorization'] = `Bearer ${adminUserToken}`;
    commit(SIGN_IN, { user: adminUserInfo, token: adminUserToken });
    commit('SET_IS_LOGGED_IN_AS', false);
    router.push({ name: localStorage.getItem('previousAdminPage') });
    localStorage.removeItem('previousAdminPage');
  },
  REMOVE_NOTIFICATION_FROM_LOGGED_IN_USER({ state, commit }, notificationId) {
    let user = improvedStructuredClone(state.user);
    const notificationIdx = user.notifications.findIndex(n => n.id === notificationId);
    if (notificationIdx > -1) {
      user.notifications.splice(notificationIdx, 1);
      localStorage.setItem(USER_INFO_NAME, JSON.stringify(user));
      commit(SET_USER, user);
    }
  },
  FORGOT_PASSWORD_EMAIL(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(ENDPOINTS.FORGOT_PASSWORD_EMAIL, data)
        .then(response => resolve(response.data))
        .catch(error =>
          reject({
            success: false,
            message:
              (error.response && error.response.data && error.response.data.message) ||
              'There was an error on the proccess, try again later'
          })
        );
    });
  },
  FORGOT_PASSWORD_CHECK_HASH(context, hash) {
    return new Promise((resolve, reject) => {
      axios
        .get(ENDPOINTS.FORGOT_PASSWORD_CHECK, { params: { hash } })
        .then(response => resolve(response.data))
        .catch(() =>
          reject({
            success: false,
            message: 'There was an error while trying to check the link, try again later'
          })
        );
    });
  },
  FORGOT_PASSWORD_CHANGE(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(ENDPOINTS.FORGOT_PASSWORD_CHANGE, data)
        .then(response => resolve(response.data))
        .catch(() =>
          reject({
            success: false,
            message:
              'There was an error while trying to change the password, try again later'
          })
        );
    });
  },
  UPDATE_LOGGED_IN_USER_OBJECT({ commit }, newUserInfo) {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const user = {
      ...userInfo,
      ...newUserInfo
    };
    localStorage.setItem(USER_INFO_NAME, JSON.stringify(user));
    commit(SET_USER, user);
  }
};

// getters
const getters = {
  isAuthenticated: state => !!state.token,
  loggedInUser: state => state.user,
  getProfileInfo: state => state.profile,
  getThemeSettings: state => state.user.theme,
  getIsLoggedInAs: state => state.isLoggedInAs
};

// mutations
const mutations = {
  [SIGN_IN](state, data) {
    const { token, user, twoFactorToken } = data;
    state.token = token;
    state.user = user;
    state.twoFactorToken = twoFactorToken;
  },
  [SIGN_OUT](state) {
    const s = initialState();
    Object.keys(s).forEach(key => {
      state[key] = s[key];
    });
  },
  [SET_USER](state, data) {
    state.user = data;
  },
  [SET_PROFILE](state, data) {
    state.profile = data;
  },
  SET_THEME_SETTINGS(state, data) {
    state.user = { ...state.user, theme: { ...state.user.theme, ...data } };
  },
  SET_IS_LOGGED_IN_AS(state, data) {
    state.isLoggedInAs = data;
  }
};

export default {
  state: { ...state },
  actions,
  getters,
  mutations
};
