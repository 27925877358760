import axios from 'axios';
import { setUiThemeColorsWithHex } from '@/uiTheme';

const FACILITATORS_ENDPOINT = 'facilitators';

const state = { facilitator: undefined };

const actions = {
  async GET_FACILITATOR({ commit }) {
    try {
      const response = await axios.get(FACILITATORS_ENDPOINT);
      if (response.data && response.data.success) {
        const facilitator = response.data.data;

        setUiThemeColorsWithHex(facilitator.branding.color, facilitator.branding.darkForm);

        document.querySelector('link[rel="icon"]').href = facilitator.branding.favIcon;

        document.documentElement.style.setProperty(
          '--facilitator-background-img-url',
          `url(${facilitator.branding.bgUrl})`
        );
        commit('SET_FACILITATOR', facilitator);
      }
    } catch (error) {
      console.log(error);
    }
  }
};

const getters = { getFacilitator: state => state.facilitator };

const mutations = {
  SET_FACILITATOR(state, facilitator) {
    state.facilitator = facilitator;
  }
};

export default {
  state: { ...state },
  actions,
  mutations,
  getters
};
