import { DateTime } from 'luxon';

/**
 * Deep clones an object or array, handling circular references
 * @param {*} obj - The object to clone
 * @param {Object} [seen] - Internal use for tracking circular references
 * @returns {*} A deep clone of the input
 */
export function improvedStructuredClone(obj) {
  if (obj === null || typeof obj !== 'object') {
    return obj;
  }

  let clone;

  try {
    clone = structuredClone(obj);
  } catch (error) {
    // If structuredClone fails, we'll do a custom clone
    clone = Array.isArray(obj) ? [] : Object.create(Object.getPrototypeOf(obj));
    Object.assign(clone, obj);
  }

  for (const key in obj) {
    if (Object.hasOwnProperty.call(obj, key)) {
      const value = obj[key];

      if (value instanceof DateTime) {
        clone[key] = DateTime.fromObject(value.toObject());
      } else if (value instanceof RegExp) {
        clone[key] = new RegExp(value.source, value.flags);
        clone[key].lastIndex = value.lastIndex;
      } else if (typeof value === 'function') {
        clone[key] = value; // Functions are not cloned, just referenced
      }
    }
  }

  return clone;
}