export const COLORS = {
  SUCCESS: '#7ED321',
  WARNING: '#E6E600',
  FAILED: '#FF151F',
  DEFAULT: '#000000'
};

export const JUPITER_APPS = {
  ACH: 'ach',
  PAYMENTS: 'payments',
  CAPITAL: 'capital',
  ADMIN: 'admin'
};

export const DB_DATETIME_FORMAT_HH_MM_SS = "yyyy-MM-dd'T'HH:mm:ss'Z'";

export const ENVIRONMENTS = {
  LOCAL: 'local',
  DEV: 'development',
  SANDBOX: 'sandbox',
  PROD: 'production'
};

export const APPS_SUBDOMAINS = {
  ACCOUNTS: 'accounts',
  PAYMENTS_LOGIN: `${JUPITER_APPS.PAYMENTS}/login`,
  PAYMENTS_ONBOARDING: `${JUPITER_APPS.PAYMENTS}-onboarding`
};

export const USER_ROLES = {
  ADMIN: 'admin',
  UNDERWRITER: 'underwriter',
  MANAGER: 'merchant-manager',
  APPLICANT: 'merchant-applicant',
  MERCHANT_ADMIN: 'merchant-admin',
  EMPLOYEE: 'merchant-employee',
  ACCOUNTING: 'merchant-accounting',
  PROVIDER: 'service-provider',
  BANK: 'bank'
};
