//https://www.rapidtables.com/convert/color/how-hex-to-rgb.html
function hexToRgb(hex) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex); //get number pairs from hex value (#{RR}{GG}{BB})
  return result
    ? {
      r: parseInt(result[1], 16), //convert to decimal (16 to 10 -> (0 - 255))
      g: parseInt(result[2], 16), //https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/parseInt
      b: parseInt(result[3], 16)
    }
    : null;
}

// https://css-tricks.com/converting-color-spaces-in-javascript/
function rgbToHsl(rgb) {
  const r = rgb.r / 255; //set values between 0 and 1
  const g = rgb.g / 255;
  const b = rgb.b / 255;
  const min = Math.min(r, g, b);
  const max = Math.max(r, g, b);
  const delta = max - min;
  let h = 0;
  let s = 0;
  let l = 0;

  if (delta === 0) {
    h = 0;
  } else if (max === r) {
    h = ((g - b) / delta) % 6;
  } else if (max === g) {
    h = (b - r) / delta + 2;
  } else if (max === b) {
    h = (r - g) / delta + 4;
  }

  h = Math.round(h * 60);

  if (h < 0) {
    //if value is less than 0, add 360 to make it positive
    h += 360;
  }

  l = (min + max) / 2; //calculate lightness

  //calculate saturation
  if (delta === 0) {
    s = 0;
  } else if (l < 0.5) {
    s = delta / (max + min);
  } else {
    s = delta / (2 - max - min);
  }

  return {
    h,
    s,
    l
  };
}

// https://stackoverflow.com/questions/2353211/hsl-to-rgb-color-conversion
function hslToRgb(hsl) {
  const h = hsl.h / 360;
  const { s, l } = hsl;

  let r;
  let g;
  let b;

  const hue2rgb = function hue2rgb(p, q, t) {
    if (t < 0) t += 1;
    if (t > 1) t--;
    if (t < 1 / 6) return p + (q - p) * 6 * t;
    if (t < 1 / 2) return q;
    if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
    return p;
  };

  if (s === 0) {
    r = g = b = l; // achromatic
  } else {
    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;
    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }

  return {
    r: Math.round(r * 255),
    g: Math.round(g * 255),
    b: Math.round(b * 255)
  };
}

function rgbToHex(rgb) {
  return `#${((1 << 24) + (rgb.r << 16) + (rgb.g << 8) + rgb.b).toString(16).slice(1)}`;
}

function lighterOrDarker(hex, lighter) {
  const rgb = hexToRgb(hex);
  const hsl = rgbToHsl(rgb);
  hsl.l = lighter ? hsl.l * 1.05 : hsl.l * 0.95;
  if (hsl.l > 1) hsl.l = 1;
  else if (hsl.l < 0) hsl.l = 0;
  const rgb2 = hslToRgb(hsl);
  return rgbToHex(rgb2);
}

function colorLerp(hex, lightness, saturation, hue) {
  const rgb = hexToRgb(hex);
  const hsl = rgbToHsl(rgb);

  if (hsl.l > 0.05) {
    hsl.s = saturation;
  }

  if (hsl.l < 0.95 && hsl.l > 0.05) {
    hsl.l = lightness;
  } else if (hsl.l < 0.05) {
    hsl.l += lightness * 0.10;
  }

  hsl.h += hue;

  const rgb2 = hslToRgb(hsl);

  return rgbToHex(rgb2);
}

function setTextColorBasedOnBgColor(hex) {
  const rgb = hexToRgb(hex);
  // calculate brightness
  const brightness = Math.round(((parseInt(rgb.r) * 299) +
    (parseInt(rgb.g) * 587) +
    (parseInt(rgb.b) * 114)) / 1000);
  return (brightness > 128) ? '#000000' : '#ffffff';
}

export function setUiThemeColorsWithHex(color, hasDarkForm) {
  document.documentElement.style.setProperty('--facilitator-theme-color', color);
  // document.documentElement.style.setProperty('--facilitator-theme-color', colorLerp(color, 0.58, 0.9, 8));
  document.documentElement.style.setProperty(
    '--facilitator-theme-bg-color',
    colorLerp(color, 0.35, 0.75, 5)
    // hasDarkForm ? colorLerp(color, 0.2, 0.6, 5) : colorLerp(color, 0.35, 0.75, 5)
  );

  document.documentElement.style.setProperty(
    '--facilitator-theme-color-lighter',
    lighterOrDarker(color, true)
  );
  document.documentElement.style.setProperty(
    '--facilitator-theme-color-darker',
    lighterOrDarker(color, false)
  );

  document.documentElement.style.setProperty('--facilitator-text-color', setTextColorBasedOnBgColor(color));
}
