<template>
  <div
    class="form-group"
    :class="{
      'input-group-focus': focused,
      'has-label': label,
    }"
    style="margin-bottom: 1rem"
  >
    <slot name="label">
      <label v-if="label" :style="labelStyle">
        <span>{{ label }}</span>
        <i v-if="required" class="required-field float-left" />
      </label>
    </slot>
    <div
      class="input-wrapper mb-0"
      @mouseenter="showIcons = false"
      @mouseleave="showIcons = true"
    >
      <DatePicker
        width="100%"
        lang="en"
        v-model="date"
        v-on="listeners"
        v-bind="$attrs"
        :input-class="inputClass"
        :not-before="notBefore"
        :placeholder="disabled ? ' ' : undefined"
        :disabled="disabled"
      >
        <i v-if="!showCalendarIcon" slot="calendar-icon" />
      </DatePicker>
      <span class="success-icon" v-if="showIcons && !error && touched">
        <i class="fal fa-check-circle" aria-hidden="true"></i>
      </span>
      <span class="error-icon" v-else-if="showIcons && error">
        <i class="fal fa-exclamation-triangle" aria-hidden="true"></i>
      </span>
    </div>
    <slot name="error" v-if="error || $slots.error">
      <i class="error-msg">{{ error }}</i>
    </slot>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';

export default {
  inheritAttrs: false,
  name: 'BaseDatePicker',
  components: { DatePicker },
  props: {
    showCalendarIcon: { type: Boolean, default: true },
    labelStyle: String,
    focus: Boolean,
    required: Boolean,
    label: {
      type: String,
    },
    error: {
      type: String,
      default: '',
    },
    value: { type: [Date, String] },
    notBefore: { type: Date, default: null },
    disabled: { type: Boolean, default: false },
  },
  model: {
    prop: 'value',
    event: 'input',
  },
  data() {
    return {
      focused: false,
      touched: false,
      date: this.value,
      showIcons: true,
    };
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: this.onInput,
        blur: this.onBlur,
        focus: this.onFocus,
      };
    },
    inputClass() {
      let inputClass = 'mx-input ';
      if (this.error) inputClass += 'error-brdr';
      else if (this.touched) inputClass += 'success-brdr';
      return inputClass;
    },
  },
  methods: {
    onInput(evt) {
      if (!this.touched) {
        this.touched = true;
      }
      this.$emit('input', evt);
    },
    onFocus() {
      this.focused = true;
    },
    onBlur() {
      this.focused = false;
      this.$emit('blur');
    },
  },
  watch: {
    value(newValue) {
      this.date = newValue;
    },
  },
  directives: {
    focus: {
      inserted: function (el, { value }, { context }) {
        context.$nextTick(() => {
          if (value) el.focus();
        });
      },
    },
  },
};
</script>

<style scoped>
.error {
  display: block;
  width: 100%;
  font-size: 80%;
  color: #ff1721 !important;
  margin-top: 5px;
}
.success-icon:hover,
.error-icon:hover {
  display: none;
}
</style>
