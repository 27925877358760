import { JUPITER_APPS, USER_ROLES } from '@/data/constants';
import { UI_ABILITIES } from '@/data/role-ui-abilities';
import config from '../config';
import store from '../store';
// const USER_TOKEN_NAME = 'user-token';
const URL_TO_REDIRECT_AFTER_LOGIN = 'urlToRedirectAfterLogin';

// Layouts
import AuthLayout from 'src/pages/Layout/AuthLayout.vue';

// General
import NotFound from 'src/pages/Common/NotFoundPage.vue';
const Dashboard = () =>
  import(/* webpackChunkName: "dashboard" */ 'src/pages/Dashboard/Dashboard.vue');
// User
const StatusOk = () =>
  import(/* webpackChunkName: "dashboard" */ 'src/pages/Status/StatusOk.vue');
const Login = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/User/Login.vue');
const TwoFactorAuthMethod = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/User/TwoFactorAuthMethod.vue');
const TwoFactorAuthCode = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/User/TwoFactorAuthCode.vue');
const Profile = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/User/Profile.vue');
const ChangePassword = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/User/ChangePassword.vue');
const ForgotPassword = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/User/ForgotPassword.vue');
const ResetPassword = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/User/ResetPassword.vue');

const Forbidden = () => import(/* webpackChunkName: "pages" */ 'src/pages/Forbidden.vue');

const isUserAuthenticated = to => {
  if (!store.getters.isAuthenticated) {
    if (to && to.name !== 'Session') {
      localStorage.setItem(URL_TO_REDIRECT_AFTER_LOGIN, JSON.stringify(to));
    }
    return false;
  }
  return true;
};

const validateUserSession = (to, from, next) => {
  if (!isUserAuthenticated(to)) {
    return next('/login');
  } else if (window.location.href.includes('accounts') && store.getters.loggedInUser) {
    const application = store.getters.loggedInUser.allowedApplications[0];
    let urlToRedirect = '';
    const hasAccessToAdminApp = store.getters.loggedInUser.acl.some(
      a => a.role === USER_ROLES.UNDERWRITER || a.role === USER_ROLES.ADMIN || a.role === USER_ROLES.BANK
    );
    if (hasAccessToAdminApp) urlToRedirect = config.ADMIN_BACKOFFICE_URL;
    else {
      switch (application) {
        case JUPITER_APPS.PAYMENTS:
          urlToRedirect = config.PAYMENTS_BACKOFFICE_URL;
          break;
        case JUPITER_APPS.ACH:
          urlToRedirect = config.ACH_BACKOFFICE_URL;
          break;
        default:
          break;
      }
    }
    if (urlToRedirect) window.location.replace(urlToRedirect);
  }
  next();
};

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next();
    return;
  }
  next('/');
};

const validateUserSessionAndRol = (to, from, next, page) => {
  if (!isUserAuthenticated(to)) {
    return next('/login');
  }
  const user = store.getters.loggedInUser;
  if (!UI_ABILITIES.check(user.acl, page)) return next('/forbidden');
  next();
};

const checkAppParamAndIfNotAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) return next('/');
  for (const key in JUPITER_APPS) {
    if (Object.hasOwnProperty.call(JUPITER_APPS, key)) {
      const element = JUPITER_APPS[key];
      if (to.params.app === element) return next();
    }
  }
  return next('/login');
};

const routes = [
  {
    path: '/',
    // redirect: '/dashboard',
    name: 'Home',
    component: Login,
    beforeEnter: validateUserSession
  },
  {
    path: '/',
    component: AuthLayout,
    name: 'Authentication',
    children: [
      {
        path: '/:app/login',
        name: 'AppLogin',
        beforeEnter: checkAppParamAndIfNotAuthenticated,
        component: Login
      },
      {
        path: '/login',
        name: 'Login',
        beforeEnter: ifNotAuthenticated,
        component: Login
      },
      {
        path: '/:app/2fa/send-code',
        name: 'TwoFactorAuthSendCode',
        beforeEnter: ifNotAuthenticated,
        component: TwoFactorAuthMethod
      },
      {
        path: '/:app/2fa/verify',
        name: 'TwoFactorAuthVerify',
        beforeEnter: ifNotAuthenticated,
        component: TwoFactorAuthCode
      },
      {
        path: '/forgot-password',
        name: 'ForgotPassword',
        beforeEnter: ifNotAuthenticated,
        component: ForgotPassword
      },
      {
        path: '/reset-password',
        name: 'ResetPassword',
        beforeEnter: ifNotAuthenticated,
        component: ResetPassword
      }
    ]
  },
  {
    path: '/',
    // redirect: '/dashboard',
    component: Login,
    name: 'Root',
    beforeEnter: validateUserSession,
    children: [
      // {
      //   path: 'profile',
      //   name: 'Profile',
      //   components: { default: Profile },
      //   meta: {
      //     title: 'Profile'
      //   }
      // },
      // {
      //   path: 'change-password',
      //   name: 'ChangePassword',
      //   components: { default: ChangePassword },
      //   meta: {
      //     title: 'Change Password'
      //   }
      // },
      {
        path: 'dashboard',
        name: 'Dashboard',
        components: { default: Dashboard },
        meta: { showFooter: true }
      },
      {
        path: 'forbidden',
        name: 'Forbidden',
        components: { default: Forbidden },
        meta: {
          title: 'Forbidden Page'
        }
      }
    ]
  },
  { path: '*', component: NotFound }
];

export default routes;
