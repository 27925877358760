import Vue from 'vue';
import Vuex from 'vuex';
// Modules
import auth from './auth';
import ui from './ui';
import facilitators from './facilitators';
import config from '../config';
import { ENVIRONMENTS } from '../data/constants';

Vue.use(Vuex);

const debug = config.ENV !== ENVIRONMENTS.PROD;

export default new Vuex.Store({
  modules: { auth, ui, facilitators },
  strict: debug
});
