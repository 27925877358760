export const Helpers = {
  methods: {
    $notifySuccess(text) {
      this.$notification('success', text);
    },
    $notifyError(text) {
      this.$notification('danger', text);
    },
    $notifyInfo(text) {
      this.$notification('info', text);
    },
    $notification(type, text, timeout = 10000) {
      this.$notify({
        message: text,
        timeout,
        // icon: 'tim-icons icon-bell-55',
        horizontalAlign: 'right',
        verticalAlign: 'top',
        type: type
      });
    }
  }
};
