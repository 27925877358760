<template>
  <div
    class="bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-animate"
    :class="switchClass"
  >
    <div
      class="bootstrap-switch-container"
      @click="!disabled ? triggerToggle() : null"
    >
      <span class="bootstrap-switch-handle-on" :class="{ disabled }">
        <slot name="on"> {{ onText }} </slot>
      </span>
      <span class="bootstrap-switch-label" :class="{ disabled }"></span>
      <span
        class="bootstrap-switch-handle-off bootstrap-switch-default"
        :class="{ disabled }"
      >
        <slot name="off"> {{ offText }} </slot>
      </span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'base-switch',
  props: {
    value: [Array, Boolean],
    onText: String,
    offText: String,
    disabled: { type: Boolean, default: false },
  },
  computed: {
    switchClass() {
      let base = 'bootstrap-switch-';
      let state = this.model ? 'on' : 'off';
      let classes = base + state;
      if (this.disabled) classes += ' disabled';
      return classes;
    },
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    triggerToggle() {
      this.model = !this.model;
    },
  },
};
</script>

<style lang="scss" scoped>
.disabled {
  cursor: not-allowed !important;
}
</style>
