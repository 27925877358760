<template>
  <li
    :class="{ 'timeline-inverted': inverted }"
    :style="{ 'margin-top': $slots.day ? '33px' : undefined }"
  >
    <!-- style="margin-top: -50px;margin-left:62px" -->
    <div v-if="$slots.day" class="day">
      <slot name="day"></slot>
    </div>
    <slot name="badge">
      <div class="timeline-badge" :class="badgeType">
        <span class="badge-label" v-if="badgeLabel">{{ badgeLabel }}</span>
        <i :class="badgeIcon" v-else></i>
      </div>
    </slot>
    <div class="timeline-panel">
      <div class="timeline-heading"><slot name="header"></slot></div>
      <div class="timeline-body" v-if="$slots.content">
        <slot name="content"></slot>
      </div>
      <div v-if="$slots.footer" class="timeline-footer">
        <slot name="footer"></slot>
      </div>
    </div>
  </li>
</template>
<script>
export default {
  name: 'time-line-item',
  props: {
    inverted: Boolean,
    badgeType: {
      type: String,
      default: 'success',
    },
    badgeIcon: {
      type: String,
      default: '',
    },
    badgeLabel: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped>
.badge-label {
  font-size: 15px;
  position: relative;
  top: -1px;
}
.day {
  position: absolute;
  top: -40px;
  left: 65px;
}
</style>
