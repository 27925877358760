<template>
  <div>
    <div class="sidebar" :data="backgroundColor">
      <div class="sidebar-wrapper" ref="sidebarScrollArea">
        <div class="logo">
          <div class="py-2 logo-mini">
            <img :src="logo" alt="app-logo" />
          </div>
          <div class="simple-text logo-normal d-flex w-100">
            <div class="mt-1 ml-1">
              {{ title }}
            </div>
            <div class="fix-sidebar-icon">
              <div class="fix-icons">
                <i
                  v-if="getThemeSettings && getThemeSettings.sidebarMini"
                  class="tim-icons icon-minimal-right"
                  @click="updateSidebarMini(false)"
                />
                <i
                  v-else
                  class="tim-icons icon-minimal-left"
                  @click="updateSidebarMini(true)"
                />
              </div>
              <div class="navbar-wrapper" style="margin-top: 7px">
                <div
                  class="navbar-toggle d-inline"
                  :class="{ toggled: $sidebar.showSidebar }"
                >
                  <button type="button" class="navbar-toggler" @click="toggleSidebar">
                    <span class="navbar-toggler-bar bar1"></span>
                    <span class="navbar-toggler-bar bar2"></span>
                    <span class="navbar-toggler-bar bar3"></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <slot></slot>
        <ul class="nav sidebar-main-nav">
          <div class="row justify-content-center mx-0">
            <i
              @click="scrollUp"
              v-if="showScrollButtons"
              class="fas fa-chevron-circle-up upButton"
            ></i>
          </div>
          <div ref="links" class="sidebar-links pb-1">
            <slot name="links">
              <sidebar-item
                v-for="(link, index) in sidebarLinks"
                :key="link.name + index"
                :link="link"
              >
                <sidebar-item
                  v-for="(subLink, index) in link.children"
                  :key="subLink.name + index"
                  :link="subLink"
                ></sidebar-item>
              </sidebar-item>
            </slot>
          </div>
          <div class="row justify-content-center mx-0">
            <i
              @click="scrollDown"
              v-if="showScrollButtons"
              class="fas fa-chevron-circle-down downButton"
            ></i>
          </div>

          <div ref="sidebarFooter" class="sidebar-footer">
            <div class="mt-4"></div>
            <!-- NotificationEventsPage -->
            <!-- v-if="UI_ABILITIES.check(loggedInUser.acl, 'sidebar.notificationEvents')" -->
            <sidebar-item
              :link="{
                name: 'Inbox',
                icon: 'fal fa-inbox',
                path: '/inbox'
              }"
            />
            <div
              v-if="userHasAccessToAnotherApp"
              id="app-container-div"
              v-click-outside="hideAppContainer"
              @click="displayAppContainer"
            >
              <sidebar-item
                :link="{
                  name: 'Jupico Apps',
                  icon: 'fal fa-th-large'
                }"
                menu
              />
            </div>
            <sidebar-item
              v-if="
                (loggedInUser.acl.find(a => a.role === 'ach-manager') ||
                  loggedInUser.acl.find(a => a.role === 'ach-admin')) &&
                UI_ABILITIES.check(loggedInUser.acl, 'sidebar.accounts-status')
              "
              :link="{
                name: 'Accounts',
                icon: 'fal fa-address-card',
                path: '/accounts-status'
              }"
            />
            <sidebar-item
              @click="handleResize"
              :link="{ name: 'Settings', icon: 'fal fa-tools' }"
            >
              <sidebar-item
                :link="{
                  name: 'Profile',
                  path: '/profile'
                }"
              />
              <sidebar-item
                :link="{
                  name: 'Change Password',
                  path: '/change-password'
                }"
              />
            </sidebar-item>
            <div id="logout-div" @click="doLogout">
              <sidebar-item
                :link="{
                  name: logOutLabel,
                  icon: 'fal fa-sign-out-alt'
                }"
                :menu="true"
              />
            </div>
          </div>
        </ul>
      </div>
    </div>
    <div v-if="displayApps" class="browse-apps-container">
      <button @click="redirectTo('payments')">
        <i class="fal fa-money-check-alt" />
        Payments
      </button>
      <button @click="redirectTo('capital')">
        <i class="fal fa-comments-alt-dollar" />
        Capital
      </button>
    </div>
    <div id="ssoCapitalLink" class="d-none">{{ getSSOLink('capital') }}</div>
    <div id="ssoPaymentsLink" class="d-none">{{ getSSOLink('payments') }}</div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Spinner from 'vue-simple-spinner';
import { Modal } from '@/components';
import { UI_ABILITIES } from '@/data/role-ui-abilities';

export default {
  name: 'sidebar',
  components: { Modal, Spinner },
  props: {
    title: { type: String, default: 'Jupico', description: 'Sidebar title' },
    shortTitle: {
      type: String,
      default: 'CT',
      description: 'Sidebar short title'
    },
    logo: {
      type: String,
      default: '/img/isologo.png',
      description: 'Sidebar app logo'
    },
    backgroundColor: {
      type: String,
      default: 'blue',
      validator: value => {
        let acceptedValues = [
          '',
          'vue',
          'blue',
          'green',
          'orange',
          'red',
          'higher-purple',
          'light-black',
          'light-gray',
          'medium-gray',
          'dark-gray',
          'white'
        ];
        return acceptedValues.indexOf(value) !== -1;
      },
      description: 'Sidebar background color'
    },
    sidebarLinks: {
      type: Array,
      default: () => [],
      description:
        "List of sidebar links as an array if you don't want to use components for these."
    },
    autoClose: {
      type: Boolean,
      default: true,
      description: 'Whether sidebar should autoclose on mobile when clicking an item'
    }
  },
  computed: {
    ...mapGetters(['getThemeSettings', 'loggedInUser', 'getIsLoggedInAs']),
    logOutLabel() {
      if (this.getIsLoggedInAs) {
        let userInfo = JSON.parse(localStorage.getItem('userInfo'));
        let username = userInfo.username;
        if (username.length > 10) username = username.substring(0, 10) + '...';
        return `Exit ${username}`;
      } else return 'Log Out';
    },
    userHasAccessToAnotherApp() {
      return (
        this.loggedInUser.acl.find(a => a.role === 'admin') ||
        this.loggedInUser.acl.find(a => a.role === 'subborrower') ||
        this.loggedInUser.acl.find(a => a.role === 'applicant-borrower') ||
        this.loggedInUser.acl.find(a => a.role === 'underwriter') ||
        this.loggedInUser.acl.find(a => a.role === 'capital-partner')
      );
    }
  },
  data() {
    return {
      searchModalVisible: false,
      searchQuery: '',
      UI_ABILITIES,
      displayApps: false,
      ssoLink: null,
      footerHeight: null,
      lastScrollPosition: 0,
      sidebarHeight: null,
      sidebarScrollHeight: null,
      showScrollButtons: false
    };
  },
  provide() {
    return {
      autoClose: this.autoClose
    };
  },
  beforeDestroy() {
    if (this.$sidebar.showSidebar) {
      this.$sidebar.showSidebar = false;
      window.removeEventListener('scroll', this.onScroll);
    }
  },
  methods: {
    scrollUp() {
      this.$nextTick(() => {
        this.$refs.links.scrollTop = 0;
      });
    },
    scrollDown() {
      this.$nextTick(() => {
        this.$refs.links.scrollTop = 10000;
      });
    },
    handleResize() {
      this.footerHeight = this.sidebarHeight;
    },
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize();
      }
    },
    toggleSidebar() {
      this.handleResize();
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    updateSidebarMini(sidebarMini) {
      this.minimizeSidebar();
      const updatedTheme = { sidebarMini };
      this.$store.dispatch('UPDATE_USER', {
        _id: this.loggedInUser.id,
        theme: updatedTheme
      });
      this.$store.dispatch('ACTION_SET_THEME_SETTINGS', updatedTheme);
    },
    doLogout() {
      if (this.getIsLoggedInAs) {
        this.$store.dispatch('EXIT_FROM_LOGGED_IN_AS');
      } else {
        const loader = this.$loading.show();
        this.$store
          .dispatch('SIGN_OUT')
          .then(() => this.$router.push('/payments/login'))
          .finally(() => loader.hide());
      }
    },
    displayAppContainer() {
      this.displayApps = !this.displayApps;
    },
    redirectTo(platform) {
      const ssoLink = this.getSSOLink(platform);
      if (platform === 'payments') window.location.href = ssoLink;
      else window.open(ssoLink);
    },
    getSSOLink(platform) {
      const token = localStorage.getItem('userToken');
      return `${
        platform === 'payments'
          ? this.$config.PAYMENT_BACKOFFICE_URL
          : this.$config.CAPITAL_BACKOFFICE_URL
      }/sso/${token}`;
    },
    hideAppContainer() {
      this.displayApps = false;
    },
    onScroll() {
      // Get the current scroll position
      const currentScrollPosition = this.$refs.links.scrollTop;
      this.lastScrollPosition = currentScrollPosition;
    },
    sidebarResize() {
      if (this.$refs?.links) {
        window.requestAnimationFrame(() => {
          const { scrollHeight, clientHeight } = this.$refs.links || {};
          this.showScrollButtons = scrollHeight > clientHeight;
        });
      }
    }
  },
  directives: {
    'click-outside': {
      bind: function (el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
          // here I check that click was outside the el and his children
          if (!(el == event.target || el.contains(event.target))) {
            // and if it did, call method provided in attribute value
            vnode.context[binding.expression](event);
          }
        };
        document.body.addEventListener('click', el.clickOutsideEvent);
      },
      unbind: function (el) {
        document.body.removeEventListener('click', el.clickOutsideEvent);
      }
    }
  },
  mounted() {
    this.$refs.links.addEventListener('scroll', this.onScroll);
    new ResizeObserver(this.sidebarResize).observe(this.$refs.links);
  }
};
</script>

<style lang="scss">
@media (min-width: 992px) {
  .navbar-search-form-mobile,
  .nav-mobile-menu {
    display: none;
  }
}

.sidebar-links {
  scroll-behavior: smooth;
  align-self: stretch;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  overflow-y: auto;
}
.sidebar-links::-webkit-scrollbar {
  display: none;
}
.sidebar-links > li {
  margin-top: 15px;
  margin-bottom: 15px;
}
.sidebar .sidebar-wrapper {
  height: 100vh !important;
}
.sidebar-main-nav {
  margin-top: 0px !important;
  overflow-y: inherit;
  display: flex !important;
  height: 87% !important;
  flex-wrap: nowrap !important;
  // justify-content: space-between !important;
  flex-direction: column !important;
  margin-bottom: 5px !important;
}
.sidebar-wrapper {
  overflow: inherit !important;
}
.sidebar-footer:before {
  content: '';
  position: absolute;

  right: 15px;
  height: 1px;
  margin-bottom: 30px;
  width: calc(100% - 30px);
  background: rgba(255, 255, 255, 0.5);
}

.sidebar-footer {
  margin-bottom: 8px;
  align-self: flex-end;
  width: 100%;
}

@media (min-height: 770px) {
  .sidebar-footer {
    min-height: inherit !important;
    position: absolute;
    bottom: 8px;
  }
  .upButton {
    display: none !important;
  }
  .downButton {
    display: none !important;
  }
}

.upButton {
  color: #a6ccff !important;
  margin-left: 12px;
  z-index: 100;
  text-shadow: 1px 1px #4958b0;
}
.downButton {
  color: #72a5ff !important;
  margin-left: 12px;
  z-index: 100;
  text-shadow: 1px 2px #4958b0;
}

.upButton:active {
  text-shadow: 0px 0px #5e72e4 !important;
}
.downButton:active {
  text-shadow: 0px 0px #5e72e4 !important;
}

.upButton:hover {
  color: #ffffff !important;
  text-shadow: 1px 1px #273061;
}

.downButton:hover {
  color: #ffffff !important;
  text-shadow: 1px 1px #273061;
}

.sidebar-mini
  > .wrapper.nav-open
  > div
  > .sidebar
  > .sidebar-wrapper
  > .sidebar-main-nav
  > .row
  > .downButton {
  opacity: 1;
}

.sidebar-mini
  > .wrapper.nav-open
  > div
  > .sidebar
  > .sidebar-wrapper
  > .sidebar-main-nav
  > .row
  > .upButton {
  opacity: 1;
}

// .sidebar-mini
//   > .wrapper
//   > div
//   > .sidebar
//   > .sidebar-wrapper
//   > .sidebar-main-nav
//   > .row
//   > .downButton {
//   opacity: 0;
// }
// .sidebar-mini
//   > .wrapper
//   > div
//   > .sidebar
//   > .sidebar-wrapper
//   > .sidebar-main-nav
//   > .row
//   > .upButton {
//   opacity: 0;
// }

.wrapper > div > .sidebar > .sidebar-wrapper > .sidebar-main-nav > .row > .downButton {
  opacity: 1;
}

.wrapper > div > .sidebar > .sidebar-wrapper > .sidebar-main-nav > .row > .upButton {
  opacity: 1;
}

#divWithButton .nav li {
  margin: 0 15px 0;
  border-radius: 30px;
  color: #ffffff;
  display: block;
  text-decoration: none;
  position: relative;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 14px;
  padding: 2px 8px;
  line-height: 1.625rem;
}
.fix-sidebar-icon {
  margin-left: auto;
  cursor: pointer;
  .fix-icons {
    margin-top: 3px;
    font-size: 1rem;
  }
  @media (max-width: 1200px) {
    .fix-icons {
      display: none;
    }
  }
  @media (min-width: 1200px) {
    .navbar-wrapper {
      display: none;
    }
  }
  .navbar-toggler-bar {
    display: block;
    position: relative;
    width: 27px;
    height: 1px;
    border-radius: 1px;
    background: white;

    &.navbar-kebab {
      height: 4px;
      width: 4px;
      margin-bottom: 3px;
      border-radius: 50%;
    }

    & + .navbar-toggler-bar {
      margin-top: 7px;
    }

    & + .navbar-toggler-bar.navbar-kebab {
      margin-top: 0px;
    }

    &.bar2 {
      width: 15px;
      transition: width 0.2s linear;
    }
  }
}

.browse-apps-container {
  position: absolute;
  padding: 15px;
  bottom: 150px;
  left: 35px;
  width: 280px;
  height: 100px;
  border-radius: 5px;
  background: #f5f6fa;
  z-index: 3000;
  border: 2px solid gray;
  display: flex;
  flex-wrap: wrap;
}

.browse-apps-container button {
  max-height: 60px;
  margin: 10px;
  border: none;
  background: none;
  cursor: pointer;
  outline: none;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.browse-apps-container button i {
  font-size: 30px;
}

.browse-apps-container button:hover {
  opacity: 0.4;
}

.browse-apps-container button:focus {
  outline: none;
}

#logout-div,
#app-container-div {
  .caret {
    display: none;
  }
}
#spinner {
  float: left;
  margin-right: 16px;
  margin-left: 6px;
}
</style>
