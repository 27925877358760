import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';
import store from '../store';

Vue.use(VueRouter);

// configure router
const router = new VueRouter({
  mode: 'history',
  routes, // short for routes: routes
  linkActiveClass: 'active',
  // eslint-disable-next-line
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  }
});

const forcePasswordResetAllowedRoutes = [
  'ResetPassword',
  'TwoFactorAuthMethod',
  'TwoFactorAuthCode',
  'Login',
  'ChangePassword',
  'ForgotPassword'
];

const validateForcePasswordReset = (to, from, next) => {
  if (to && forcePasswordResetAllowedRoutes.indexOf(to.name) < 0) {
    //check if admin set force-reset-password flag to true
    // to force user to set the password again (or first time by default)
    const forceReset = store.getters.loggedInUser.forcePasswordReset;
    const isLoggedInAs = store.getters.getIsLoggedInAs;
    if (forceReset && !isLoggedInAs) {
      return next('/change-password');
    }
  }
  next();
};

// router.beforeEach((to, from, next) => {
//   validateForcePasswordReset(to, from, next);
// });

export default router;
