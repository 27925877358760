<template>
  <div
    class="main-div"
    :class="{
      'input-group-focus': focused,
      'has-label': label
    }"
    style="margin-bottom: 1rem"
  >
    <slot name="label">
      <label v-if="label" class="input-label" :style="labelStyle">
        <span>{{ label }}</span>
        <i
          v-if="required"
          :class="`required-field align-self-center float-${requiredDotPosition}`"
        />
      </label>
    </slot>
    <div
      class="input-wrapper mb-0"
      :class="{
        'input-group': hasIcon
      }"
    >
      <slot name="addonLeft">
        <span v-if="addonLeftIcon" class="input-group-prepend">
          <div
            class="input-group-text"
            :class="{
              'error-brdr': error,
              'success-brdr': !error && touched
            }"
          >
            <i :class="addonLeftIcon"></i>
          </div>
        </span>
      </slot>
      <slot>
        <Select
          ref="select"
          v-focus="focus"
          class="select-primary"
          filterable
          v-on="listeners"
          :name="name"
          :value="value"
          :placeholder="placeholder"
          :class="{
            'error-border': hasValidation && error,
            'success-border': hasValidation && !error && touched
          }"
          :disabled="disabled"
          :popperClass="popperClass"
          :filter-method="filterMethod"
          :multiple="multiple"
          :allow-create="allowCreate"
          :collapse-tags="collapseTags"
          :loading="loading"
        >
          <slot name="options">
            <Option
              v-for="(option, index) in options"
              :class="option.class ?? 'select-primary'"
              :key="option.value + index"
              :label="option.label"
              :value="option.value"
              :disabled="option.disabled"
            />
          </slot>
        </Select>
      </slot>
      <span
        class="success-icon hidden-xs d-none d-lg-block"
        v-if="hasValidation && !error && touched"
      >
        <i class="fal fa-check-circle" aria-hidden="true"></i>
      </span>
      <span class="error-icon" v-else-if="error">
        <i class="fal fa-exclamation-triangle" aria-hidden="true"></i>
      </span>
    </div>
    <slot name="error" v-if="error || $slots.error">
      <i class="error-msg">{{ error }}</i>
    </slot>
  </div>
</template>

<script>
import { Select, Option } from 'element-ui';

export default {
  inheritAttrs: false,
  components: {
    Select,
    Option
  },
  name: 'BaseSelect',
  props: {
    requiredDotPosition: { type: String, default: 'left' },
    labelStyle: String,
    options: Array,
    focus: Boolean,
    required: Boolean,
    label: { type: String },
    error: { type: String, default: '' },
    value: { type: [String, Number, Array] },
    addonLeftIcon: { type: String, description: 'Input icon on the left' },
    hasValidation: { type: Boolean, default: false },
    placeholder: { type: String, default: ' ' },
    disabled: Boolean,
    filterMethod: Function,
    multiple: Boolean,
    allowCreate: Boolean,
    collapseTags: Boolean,
    popperClass: String,
    name: String,
    loading: Boolean
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  data() {
    return {
      focused: false,
      touched: false
    };
  },
  computed: {
    hasIcon() {
      const { addonLeft } = this.$slots;
      return addonLeft !== undefined || this.addonLeftIcon !== undefined;
    },
    listeners() {
      return {
        ...this.$listeners,
        input: this.onInput,
        blur: this.onBlur,
        focus: this.onFocus
      };
    }
  },
  methods: {
    onInput(evt) {
      if (Array.isArray(evt)) {
        if (evt.length > 0)
          if (!this.touched) {
            this.touched = true;
          }
      } else if (!this.touched) {
        this.touched = true;
      }
      this.$emit('input', evt);
    },
    onFocus() {
      this.focused = true;
      this.$emit('onFocus');
    },
    onBlur() {
      this.focused = false;
    },
    reset() {
      this.touched = false;
    },
    setFocus() {
      this.$refs.select.focus();
    },
    blur() {
      this.$refs.select.blur();
    }
  },
  directives: {
    focus: {
      inserted: function (el, { value }, { context }) {
        context.$nextTick(() => {
          if (value) el.children[0].children[0].focus();
        });
      }
    }
  }
};
</script>

<style scoped>
.error {
  display: block;
  width: 100%;
  font-size: 80%;
  color: #ff1721 !important;
  margin-top: 5px;
}
.success-icon {
  position: absolute;
  right: 10px;
  font-size: 10px;
}
.error-icon {
  position: absolute;
  right: 10px;
  line-height: 1.5;
}
.main-div div {
  width: 100%;
}
.no-caret .select-primary i {
  display: none;
}
</style>
