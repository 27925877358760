export const UI_ABILITIES = {
  check: function (acl, ability) {
    try {
      for (const a of acl) {
        if (UI_ABILITIES[a.role] && UI_ABILITIES[a.role].includes(ability))
          return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  }
};
