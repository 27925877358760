/* eslint-disable */
//process.env.VUE_APP_NODE_ENV this is how we obtain the setting for vue
let config = {};
let hostname = window.location.hostname;
let host = window.location.host;
let domain = hostname.split('.').splice(-2).join('.');
config.BASE_DOMAIN = domain;

console.log('hostname ' + hostname);
//PROD ENV
if (hostname === 'accounts.jupiterhq.com') {
  console.log('accounts.jupiterhq.com');
  config.ENV = 'production';
  config.ROOT_API = 'https://accounts-api.jupiterhq.com/api/v1';
  config.PAYMENTS_BACKOFFICE_URL = 'https://payments.jupiterhq.com';
  config.ACH_BACKOFFICE_URL = 'https://ach.jupiterhq.com';
  config.CAPITAL_BACKOFFICE_URL = 'https://capital.jupiterhq.com';
  config.ADMIN_BACKOFFICE_URL = 'https://admin.jupiterhq.com';
  config.HOSTED_PAGE_URL = 'https://payments-hosted-pages.jupiterhq.com';
  document.title = 'Jupico Backoffice';
  //PROD JUPICO
} else if (hostname === 'accounts.jupico.com') {
  console.log('accounts.jupico.com');
  config.ENV = 'production';
  config.ROOT_API = 'https://accounts-api.jupico.com/api/v1';
  config.PAYMENTS_BACKOFFICE_URL = 'https://payments.jupico.com';
  config.ACH_BACKOFFICE_URL = 'https://ach.jupico.com';
  config.CAPITAL_BACKOFFICE_URL = 'https://capital.jupico.com';
  config.ADMIN_BACKOFFICE_URL = 'https://admin.jupico.com';
  config.HOSTED_PAGE_URL = 'https://payments-hosted-pages.jupico.com';
  document.title = 'Jupico Backoffice';
  //SANDBOX
} else if (hostname === 'sandbox-accounts.jupiterhq.com') {
  console.log('sandbox-accounts.jupiterhq.com');
  config.ENV = 'sandbox';
  config.ROOT_API = 'https://sandbox-accounts-api.jupiterhq.com/api/v1';
  config.PAYMENTS_BACKOFFICE_URL = 'https://sandbox-payments.jupiterhq.com';
  config.ACH_BACKOFFICE_URL = 'https://sandbox-ach.jupiterhq.com';
  config.CAPITAL_BACKOFFICE_URL = 'https://sandbox-capital.jupiterhq.com';
  config.ADMIN_BACKOFFICE_URL = 'https://sandbox-admin.jupiterhq.com';
  config.HOSTED_PAGE_URL = 'https://sandbox-payments-hosted-pages.jupiterhq.com';
  document.title = 'Jupico Backoffice';
  //SANDBOX JUPICO
} else if (hostname === 'sandbox-accounts.jupico.com') {
  console.log('sandbox-accounts.jupico.com');
  config.ENV = 'sandbox';
  config.ROOT_API = 'https://sandbox-accounts-api.jupico.com/api/v1';
  config.PAYMENTS_BACKOFFICE_URL = 'https://sandbox-payments.jupico.com';
  config.ACH_BACKOFFICE_URL = 'https://sandbox-ach.jupico.com';
  config.CAPITAL_BACKOFFICE_URL = 'https://sandbox-capital.jupico.com';
  config.ADMIN_BACKOFFICE_URL = 'https://sandbox-admin.jupico.com';
  config.HOSTED_PAGE_URL = 'https://sandbox-payments-hosted-pages.jupico.com';
  document.title = 'Jupico Backoffice';
  //STAGING JUPITERHQ
} else if (hostname === 'staging-accounts.jupiterhq.com') {
  console.log('staging-accounts.jupiterhq.com');
  config.ENV = 'sandbox';
  config.ROOT_API = 'https://staging-accounts-api.jupiterhq.com/api/v1';
  config.PAYMENTS_BACKOFFICE_URL = 'https://staging-payments.jupiterhq.com';
  config.ACH_BACKOFFICE_URL = 'https://staging-ach.jupiterhq.com';
  config.CAPITAL_BACKOFFICE_URL = 'https://staging-capital.jupiterhq.com';
  config.ADMIN_BACKOFFICE_URL = 'https://staging-admin.jupiterhq.com';
  config.HOSTED_PAGE_URL = 'https://staging-payments-hosted-pages.jupiterhq.com';
  document.title = 'Jupico Backoffice';
  //STAGE JUPITERHQ
} else if (hostname === 'stage-accounts.jupiterhq.com') {
  console.log('stage-accounts.jupiterhq.com');
  config.ENV = 'sandbox';
  config.ROOT_API = 'https://stage-accounts-api.jupiterhq.com/api/v1';
  config.PAYMENTS_BACKOFFICE_URL = 'https://stage-payments.jupiterhq.com';
  config.ACH_BACKOFFICE_URL = 'https://stage-ach.jupiterhq.com';
  config.CAPITAL_BACKOFFICE_URL = 'https://stage-capital.jupiterhq.com';
  config.ADMIN_BACKOFFICE_URL = 'https://stage-admin.jupiterhq.com';
  config.HOSTED_PAGE_URL = 'https://stage-payments-hosted-pages.jupiterhq.com';
  document.title = 'Jupico Backoffice';
  //STAGING JUPICO
} else if (hostname === 'staging-accounts.jupico.com') {
  console.log('staging-accounts.jupico.com');
  config.ENV = 'sandbox';
  config.ROOT_API = 'https://staging-accounts-api.jupico.com/api/v1';
  config.PAYMENTS_BACKOFFICE_URL = 'https://staging-payments.jupico.com';
  config.ACH_BACKOFFICE_URL = 'https://staging-ach.jupico.com';
  config.CAPITAL_BACKOFFICE_URL = 'https://staging-capital.jupico.com';
  config.ADMIN_BACKOFFICE_URL = 'https://staging-admin.jupico.com';
  config.HOSTED_PAGE_URL = 'https://staging-payments-hosted-pages.jupico.com';
  document.title = 'Jupico Backoffice';
  // STAGE JUPICO
} else if (hostname === 'stage-accounts.jupico.com') {
  console.log('stage-accounts.jupico.com');
  config.ENV = 'sandbox';
  config.ROOT_API = 'https://stage-accounts-api.jupico.com/api/v1';
  config.PAYMENTS_BACKOFFICE_URL = 'https://stage-payments.jupico.com';
  config.ACH_BACKOFFICE_URL = 'https://stage-ach.jupico.com';
  config.CAPITAL_BACKOFFICE_URL = 'https://stage-capital.jupico.com';
  config.ADMIN_BACKOFFICE_URL = 'https://stage-admin.jupico.com';
  config.HOSTED_PAGE_URL = 'https://local-payments-hosted-pages stage-admin.jupico.com';
  document.title = 'Jupico Backoffice';
  // STAGING PAY FAC
} else if (hostname === 'staging-accounts.payfac.io') {
  console.log('STAGING PAY FAC staging-accounts.payfac.io ');
  config.ENV = 'sandbox';
  config.ROOT_API = 'https://staging-accounts-api.payfac.io/api/v1';
  config.PAYMENTS_BACKOFFICE_URL = 'https://staging-payments.payfac.io';
  config.ACH_BACKOFFICE_URL = 'https://staging-ach.payfac.io';
  config.CAPITAL_BACKOFFICE_URL = 'https://staging-capital.payfac.io';
  config.ADMIN_BACKOFFICE_URL = 'https://staging-admin.payfac.io';
  config.HOSTED_PAGE_URL = 'https://local-payments-hosted-pages staging-admin.payfac.io';
  document.title = 'Payfac Backoffice';
  //STAGE PAY FAC
} else if (hostname === 'stage-accounts.payfac.io') {
  console.log('STAGE PAY FAC stage-accounts.payfac.io ');
  config.ENV = 'sandbox';
  config.ROOT_API = 'https://stage-accounts-api.payfac.io/api/v1';
  config.PAYMENTS_BACKOFFICE_URL = 'https://stage-payments.payfac.io';
  config.ACH_BACKOFFICE_URL = 'https://stage-ach.payfac.io';
  config.CAPITAL_BACKOFFICE_URL = 'https://stage-capital.payfac.io';
  config.ADMIN_BACKOFFICE_URL = 'https://stage-admin.payfac.io';
  config.HOSTED_PAGE_URL = 'https://stage-payments-hosted-pages.payfac.io';
  document.title = 'Payfac Backoffice';
  //DEV ENV
} else if (hostname === 'dev-accounts.jupiterhq.com') {
  console.log('DEV dev-accounts.jupiterhq.com ');
  config.ENV = 'development';
  config.ROOT_API = 'https://dev-accounts-api.jupiterhq.com/api/v1';
  config.PAYMENTS_BACKOFFICE_URL = 'https://dev-payments.jupiterhq.com';
  config.ACH_BACKOFFICE_URL = 'https://dev-ach.jupiterhq.com';
  config.CAPITAL_BACKOFFICE_URL = 'https://dev-capital.jupiterhq.com';
  config.ADMIN_BACKOFFICE_URL = 'https://dev-admin.jupiterhq.com';
  config.HOSTED_PAGE_URL = 'https://dev-payments-hosted-pages.jupiterhq.com';
  document.title = 'Jupico Backoffice';
  //DEV JUPICO
} else if (hostname === 'dev-accounts.jupico.com') {
  console.log('DEV dev-accounts.jupico.com ');
  config.ENV = 'development';
  config.ROOT_API = 'https://dev-accounts-api.jupico.com/api/v1';
  config.PAYMENTS_BACKOFFICE_URL = 'https://dev-payments.jupico.com';
  config.ACH_BACKOFFICE_URL = 'https://dev-ach.jupico.com';
  config.CAPITAL_BACKOFFICE_URL = 'https://dev-capital.jupico.com';
  config.ADMIN_BACKOFFICE_URL = 'https://dev-admin.jupico.com';
  config.HOSTED_PAGE_URL = 'https://dev-payments-hosted-pages.jupico.com';
  document.title = 'Jupico Backoffice';
} else if (hostname === 'dev-accounts.payfac.io') {
  console.log('DEV DEV-accounts.payfac.io ');
  config.ENV = 'development';
  config.ROOT_API = 'https://dev-accounts-api.payfac.io/api/v1';
  config.PAYMENTS_BACKOFFICE_URL = 'https://dev-payments.payfac.io';
  config.ACH_BACKOFFICE_URL = 'https://dev-ach.payfac.io';
  config.CAPITAL_BACKOFFICE_URL = 'https://dev-capital.payfac.io';
  config.ADMIN_BACKOFFICE_URL = 'https://dev-admin.payfac.io';
  config.HOSTED_PAGE_URL = 'https://dev-payments-hosted-pages.payfac.io';
  document.title = 'Payfac Backoffice';
} else if (hostname === 'sandbox-accounts.payfac.io') {
  // SANDBOX PAY FAC
  console.log('SANDBOX PAY FAC sandbox-accounts.payfac.io ');
  config.ENV = 'sandbox';
  config.ROOT_API = 'https://sandbox-accounts-api.payfac.io/api/v1';
  config.PAYMENTS_BACKOFFICE_URL = 'https://sandbox-payments.payfac.io';
  config.ACH_BACKOFFICE_URL = 'https://sandbox-ach.payfac.io';
  config.CAPITAL_BACKOFFICE_URL = 'https://sandbox-capital.payfac.io';
  config.ADMIN_BACKOFFICE_URL = 'https://sandbox-admin.payfac.io';
  config.HOSTED_PAGE_URL = 'https://sandbox-payments-hosted-pages.payfac.io';
  document.title = 'Payfac Backoffice';
} else if (hostname === 'local-accounts.jupiterhq.com') {
  //LOCAL WITH DOMAIN
  console.log('local-accounts.jupiterhq.com');
  config.ENV = 'local';
  config.ROOT_API = 'http://local-accounts-api.jupiterhq.com/api/v1';
  config.PAYMENTS_BACKOFFICE_URL = 'http://local-payments.jupiterhq.com';
  config.ACH_BACKOFFICE_URL = 'http://local-ach.jupiterhq.com';
  config.CAPITAL_BACKOFFICE_URL = 'http://local-capital.jupiterhq.com';
  config.ADMIN_BACKOFFICE_URL = 'http://local-admin.jupiterhq.com';
  config.HOSTED_PAGE_URL = 'http://local-payments-hosted-pages.jupiterhq.com';
  document.title = 'Jupico Backoffice';
  //LOCAL WITH DOMAIN
} else if (hostname === 'local-accounts.payfac.io') {
  console.log('local-accounts.payfac.io');
  config.ENV = 'local';
  config.ROOT_API = 'http://local-accounts-api.payfac.io/api/v1';
  config.PAYMENTS_BACKOFFICE_URL = 'http://local-payments.payfac.io';
  config.ACH_BACKOFFICE_URL = 'http://local-ach.payfac.io';
  config.CAPITAL_BACKOFFICE_URL = 'http://local-capital.payfac.io';
  config.ADMIN_BACKOFFICE_URL = 'http://local-admin.payfac.io';
  config.HOSTED_PAGE_URL = 'http://local-payments-hosted-pages.payfac.io';
  document.title = 'Payfac Backoffice';
  // PROD LOCAL STATION
} else if (hostname === 'local-accounts.jupico.com') {
  console.log('local-accounts.jupico.com');
  config.ENV = 'local';
  config.ROOT_API = 'http://local-accounts-api.jupico.com/api/v1';
  config.PAYMENTS_BACKOFFICE_URL = 'http://local-payments.jupico.com';
  config.ACH_BACKOFFICE_URL = 'http://local-ach.jupico.com';
  config.CAPITAL_BACKOFFICE_URL = 'http://local-capital.jupico.com';
  config.ADMIN_BACKOFFICE_URL = 'http://local-admin.jupico.com';
  config.HOSTED_PAGE_URL = 'http://local-payments-hosted-pages.jupico.com';
  document.title = 'Jupico Backoffice';
  // PROD LOCAL STATION
} else if (host === 'localhost:8080') {
  console.log('PROD LOCAL STATION');
  config.ENV = 'local';
  config.ROOT_API = 'http://localhost:8040/api/v1';
  config.PAYMENTS_BACKOFFICE_URL = 'http://localhost:8180';
  config.ACH_BACKOFFICE_URL = 'http://localhost:8280';
  config.CAPITAL_BACKOFFICE_URL = 'http://localhost:8380';
  config.ADMIN_BACKOFFICE_URL = 'http://localhost:8480';
  config.BASE_DOMAIN = 'localhost';
  document.title = 'Jupico Backoffice';
} else {
  console.log('DEFAULT LOCAL THIS IS AN ERROR'); // DEFAULT LOCAL
  // config.ENV = 'local';
  // config.ROOT_API = 'http://localhost:8040/api/v1';
  // config.PAYMENTS_BACKOFFICE_URL = 'http://localhost:8180';
  // config.ACH_BACKOFFICE_URL = 'http://localhost:8280';
  // config.CAPITAL_BACKOFFICE_URL = 'http://localhost:8380';
  // config.BASE_DOMAIN = 'localhost';
}

export default config;
