<template>
  <section
    v-if="isIE"
    class="main-body-wrapper"
    style="
      display: inline-block;
      width: 100%;
      text-align: center;
      font-size: 18px;
      color: #535a72;
      font-weight: 300;
      margin: 100px auto 40px;
    "
  >
    <div class="container p-0">
      <card
        class="mx-auto"
        style="padding: 48px 40px; max-width: 830px"
        bodyClasses="p-0"
      >
        <h1 class="mb-2">Unsupported Browser</h1>
        <p>
          Jupico is committed to providing modern, secure technologies to our
          customers and as such we no longer support the Internet Explorer 11
          browser per Microsoft’s recommendation. Please access this application
          from any of the following browsers:
          <a href="https://www.microsoft.com/edge" target="_blank"
            >Microsoft Edge</a
          >, <a href="https://www.google.com/chrome" target="_blank">Chrome</a>,
          <a href="https://www.mozilla.com" target="_blank">Firefox</a>, or
          <a href="https://www.apple.com/safari" target="_blank">Safari</a>
        </p>
      </card>
    </div>
  </section>
  <router-view v-else></router-view>
</template>

<script>
import axios from 'axios';
import bowser from 'bowser';
import { mapGetters } from 'vuex';
import { Helpers } from '@/mixins/Helpers';
import swal from 'sweetalert2';

export default {
  mixins: [Helpers],
  data() {
    return {
      currentVersion: undefined,
      isSwalOpen: false,
    };
  },
  computed: {
    ...mapGetters(['getThemeSettings', 'isAuthenticated']),
    isIE() {
      try {
        return (
          bowser.getParser(window.navigator.userAgent).getBrowserName() ===
          'Internet Explorer'
        );
      } catch (error) {
        return false;
      }
    }
  },
  created() {
    axios.interceptors.response.use(
      response => {
        if(!this.currentVersion){
          this.currentVersion = response.headers['x-app-version'];
        }
        if(
          response.headers['x-app-version'] &&
          response.headers['x-app-version'] !== this.currentVersion &&
          this.isSwalOpen === false
        ) {
          this.isSwalOpen = true;
          swal.fire({
            title: 'New Version Available',
            text:
              'A new version of the application is available. Please log back in to get the latest version.',
            icon: 'info',
            confirmButtonText: 'OK',
            showCancelButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            reverseButtons: true,
            focusConfirm: true,
            focusCancel: false,
            showCloseButton: false,
            showLoaderOnConfirm: true,
            preConfirm: () => {
              //option 1
              // localStorage.clear();
              // sessionStorage.clear();
              // window.location.replace(window.location.href);

              // option 2
              this.$store.dispatch('SIGN_OUT').then(() => {
                window.location.reload();
              });

              this.isSwalOpen = false;
            }
          });
        }
        return response;
      },
      error => {
        if (
          error.response.status === 401 &&
          error.response.data.message.includes('expired')
        )
          if (this.isAuthenticated) this.dispatchSignOutExpired();
        return Promise.reject(error);
      }
    );
  },
  mounted() {
    this.updateUI();
  },
  onIdle() {
    if (this.isAuthenticated) this.dispatchSignOutExpired();
  },
  methods: {
    updateUI() {
      if (this.getThemeSettings) {
        if (this.getThemeSettings.mode === 'dark')
          document.body.classList.remove('white-content');
        else document.body.classList.add('white-content');
        if (this.getThemeSettings.sidebarMini !== this.$sidebar.isMinimized)
          this.$sidebar.toggleMinimize();
      }
    },
    dispatchSignOutExpired() {
      this.$store.dispatch('SIGN_OUT').then(() => {
        this.$router.push('/login');
        this.$notifyInfo(
          'Your session has expired (after 60 mins of inactivity).\nPlease log in again to continue.'
        );
      });
    }
  },
  watch: {
    getThemeSettings: function () {
      this.updateUI();
    }
  }
};
</script>

<style lang="scss">
@import '../node_modules/vue-loading-overlay/dist/vue-loading.css';
@import './assets/sass/styles/styles.scss';

.swal2-container {
  z-index: 10060 !important;
}
</style>
