import { Input, InputNumber, Tooltip, Popover } from 'element-ui';
import BaseRadio from 'src/components/Inputs/BaseRadio';
import BaseInput from 'src/components/Inputs/BaseInput.vue';
import BaseDropdown from 'src/components/BaseDropdown.vue';
import Card from 'src/components/Cards/Card.vue';
import BaseButton from 'src/components/BaseButton.vue';
import BaseCheckbox from 'src/components/Inputs/BaseCheckbox.vue';
import BaseCustomInput from 'src/components/Inputs/BaseCustomInput.vue';
import BaseMaskedInput from 'src/components/Inputs/BaseMaskedInput.vue';
import BaseNumericInput from 'src/components/Inputs/BaseNumericInput';
import BaseDatePicker from 'src/components/Inputs/BaseDatePicker';
import BaseSelect from 'src/components/Inputs/BaseSelect';
import BaseSwitch from 'src/components/BaseSwitch';
/**
 * You can register global components here and use them as a plugin in your main Vue instance
 */

const GlobalComponents = {
  install(Vue) {
    Vue.component(BaseInput.name, BaseInput);
    Vue.component(BaseRadio.name, BaseRadio);
    Vue.component(BaseCustomInput.name, BaseCustomInput);
    Vue.component(BaseMaskedInput.name, BaseMaskedInput);
    Vue.component(BaseNumericInput.name, BaseNumericInput);
    Vue.component(BaseDatePicker.name, BaseDatePicker);
    Vue.component(BaseSelect.name, BaseSelect);
    Vue.component(BaseDropdown.name, BaseDropdown);
    Vue.component(Card.name, Card);
    Vue.component(BaseCheckbox.name, BaseCheckbox);
    Vue.component(BaseButton.name, BaseButton);
    Vue.component(Input.name, Input);
    Vue.component(InputNumber.name, InputNumber);
    Vue.component(BaseSwitch.name, BaseSwitch);
    Vue.use(Tooltip);
    Vue.use(Popover);
  },
};

export default GlobalComponents;
